<template>
    <div class="page_container" :class="{'straight_length': borderPosition}">
        <div class="page_head">
            <div class="page_head-item">
                <div class="page_head-title">
                    DASHBOARD
                </div>
            </div>
        </div>

        <div class="head_subtitle">
            <div class="head_subtitle-text">Lead Overview</div>
            <div>Dec 16th -Jan 14th</div>
        </div>
        <div class="lead_box-container">
            <div class="lead_box-item buyer_lead">
                10
                <div class="lead_box-item-text">Buyer Leads</div>
            </div>
            <div class="lead_box-item seller_lead">
                10
                <div class="lead_box-item-text">Seller Leads</div>
            </div>
            <div class="lead_box-item partial_lead">
                20
                <div class="lead_box-item-text">Partial Leads</div>
            </div>
            <div class="lead_box-item address_capture">
                30
                <div class="lead_box-item-text">Address captures</div>
            </div>
        </div>

        <div class="head_subtitle">
            <div class="head_subtitle-text">Overview</div>
        </div>
        <div class="lead_box-container">
            <div class="lead_box-item-bordered">
                10
                <div class="lead_box-item-bordered-text">Emails opened in the last 29 days</div>
            </div>
            <div class="lead_box-item-bordered">
                10
                <div class="lead_box-item-bordered-text">Texts received from leads</div>
            </div>
            <div class="lead_box-item-bordered">
                20
                <div class="lead_box-item-bordered-text">Emails received from leads</div>
            </div>
            <div class="lead_box-item-bordered">
                30
                <div class="lead_box-item-bordered-text">Unread messages</div>
            </div>
        </div>

        <div class="head_subtitle">
            <div class="head_subtitle-text">Executive Assistant</div>
        </div>
        <div class="lead_box-container">
            <div class="lead_box-item-grey">
                4
                <div class="lead_box-item-grey-text">Ongoing Conversation</div>
            </div>
            <div class="lead_box-item-grey">
                1
                <div class="lead_box-item-grey-text">Appointments set</div>
            </div>
            <div class="lead_box-item-grey">
                25%
                <div class="lead_box-item-grey-text">Response</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MainDashboard",
        data() {
            return {
                borderPosition: true,
            }
        },
        created() {
            alert.$on("clickOn",border =>{
                this.borderPosition = border;
            })
        },
        methods: {

        }
    }
</script>

<style scoped lang="scss">
.page {
    &_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1700px;
        width: 100%;
        margin: 0 auto;
        transition: .4s;
        & .head {
            &_subtitle {
                width: 100%;
                padding: 25px 30px;
                display: flex;
                align-items: center;
                &-text {
                    color: $light_blue;
                    font-size: 20px;
                    font-weight: 500;
                    margin-right: 30px;
                }
            }
        }
        & .lead_box {
            &-container {
                padding: 40px 30px;
                display: flex;
                justify-content: space-between;
                width: 100%;
            }
            &-item {
                max-width: 360px;
                width: 100%;
                height: 165px;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 5px;
                color: $white;
                font-weight: 500;
                font-size: 35px;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                &-text {
                    font-size: 25px;
                }
                &.buyer_lead {
                    background: $light_blue;
                }
                &.seller_lead {
                    background: #92B4CB;
                }
                &.partial_lead {
                    background: #7BB5DB;
                }
                &.address_capture {
                    background: #89A8BE;
                }
            }
            &-item-bordered {
                max-width: 360px;
                width: 100%;
                height: 165px;
                box-shadow: 0 3px 6px #00000029;
                border-radius: 5px;
                border: 1px solid $light_blue;
                color: $light_blue;
                font-weight: 500;
                font-size: 35px;
                display: flex;
                justify-content: space-around;
                flex-direction: column;
                align-items: center;
                padding: 25px 35px;
                &-text {
                    color: $black;
                    font-size: 24px;
                    line-height: 28px;
                    text-align: center;
                }
            }
            &-item-grey {
                max-width: 450px;
                width: 100%;
                height: 140px;
                box-shadow: 0 3px 6px #00000029;
                border-radius: 5px;
                background: #E6ECEE;
                color: $light_blue;
                font-weight: 500;
                font-size: 35px;
                display: flex;
                justify-content: space-around;
                flex-direction: column;
                align-items: center;
                padding: 25px 35px;
                &-text {
                    color: $black;
                    font-size: 24px;
                    line-height: 28px;
                    text-align: center;
                }
            }

        }

        &.straight_length {
            max-width: 1600px;
            transition: .4s;
        }
    }
    &_head {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 30px 5px;

        &-item {
            display: flex;
            align-items: center;
        }

        &-title {
            color: $main_blue;
            font-size: 25px;
            font-weight: 500;
            letter-spacing: 0;
            text-transform: uppercase;
        }

    }
}
</style>